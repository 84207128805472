import { ThemeOptions } from '@mui/material/styles';

export const defautlThemeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            light: "#0076b62b",
            main: "#0d1494"
        },
        secondary: {
            light: "#919EAB4D",
            main: "#8a8d93"
        },
        info:{
            light: "#16b1ff1f",
            main: "#16b1ff"
        },
        warning: {
            light: "#FFAB0029",
            main: "#FFAB00"
        },
        success: {
            light: "#36B37E29",
            main: "#00AB55",
        },
        error:{
            light: "#FF563029",
            main: "#FF5630",
        }
    },
    typography:{
        fontFamily:"Inter san-serif"
    },
    components:{
        MuiCssBaseline:{
            styleOverrides:{
                scrollbarWidth: "thin",
                '&::-webkit-scrollbar': {
                    width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: "#919eab1f",
                    borderRadius: "100px",
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: "#8a8d93",
                    borderRadius: "100px"
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#8a8d93'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontFamily: ["Inter", "Julius Sans One", "sans-serif"]
                },
                h1:{
                    fontSize: "56px",
                    fontWeight: "700",
                    fontFamily: "Julius Sans One",
                    "@media(max-width: 900px)":{
                        fontSize: "42px"
                    }
                },
                h2:{
                    fontSize: "40px",
                    fontWeight: "700",
                    fontFamily: "Julius Sans One",
                    "@media(max-width: 900px)":{
                        fontSize: "28px"
                    }
                },
                h3:{
                    fontSize: "30px",
                    fontWeight: "700",
                    fontFamily: "Julius Sans One",
                    "@media(max-width: 900px)":{
                        fontSize: "26px"
                    }
                },
                h4:{
                    fontSize: "24px",
                    fontWeight: "700",
                    fontFamily: "Julius Sans One",
                    "@media(max-width: 900px)":{
                        fontSize: "22px"
                    },
                },
                h5:{
                    fontSize: "20px",
                    fontWeight: "700",
                    fontFamily: "Julius Sans One",
                    "@media(max-width: 900px)":{
                        fontSize: "18px"
                    }
                },
                h6:{
                    fontSize: "20px",
                    fontWeight: "400",
                    fontFamily: "Inter",
                    "@media(max-width: 900px)":{
                        fontSize: "18px"
                    }
                },
                subtitle1: {
                    fontSize: "20px",
                    fontWeight: "600",
                    fontFamily: "Inter",
                    "@media(max-width: 900px)":{
                        fontSize: "18px"
                    }
                },
                subtitle2: {
                    fontSize: "18px",
                    fontWeight: "400",
                    fontFamily: "Inter",
                    "@media(max-width: 900px)":{
                        fontSize: "16px"
                    }
                },
                body1: {
                    fontSize: "16px",
                    fontWeight: "400",
                    fontFamily: "Inter"
                },
                body2: {
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Inter"
                },
                button: {
                    fontSize: "16px",
                    fontWeight: "600",
                    fontFamily: "Inter"
                },
                caption: {
                    fontSize: "12px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                }
            }
        },
        MuiCard: {
            defaultProps:{
                elevation: 2
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 2
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },    
            },
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
            defaultProps: {
                disableRipple: true,
                variant: "contained",
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
            },
            styleOverrides: {
                root: {
                    borderRadius: "1rem",
                    ".MuiInputLabel-root":{
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Inter"
                    },
                    ".MuiInputBase-input":{
                        fontSize: "18px",
                        fontWeight: "400",
                        fontFamily: "Inter",
                    },
                    ".MuiFormHelperText-root":{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Inter"
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides:{
                listbox: {
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "Inter"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                }
            }
        },
        MuiTablePagination:{
            styleOverrides:{
                root: {
                    ".MuiTablePagination-selectLabel": {
                        fontFamily: "Inter",
                    },
                    ".MuiTablePagination-displayedRows":{
                        fontFamily: "Inter",
                    }   
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontFamily: "Inter"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontFamily: "Inter"
                }
            }
        }
    }
}