import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";
import { ContactState, initialState } from "~/redux/contact/contact.state";
import {initContactStateAction, sendEmailAction} from "~/redux/contact/contact.actions"


export const contactReducer = createReducer(
    initialState,
    (builder: ActionReducerMapBuilder<ContactState>) =>
        builder  
        
    //------------------ Init State ----------------------------
    .addCase(initContactStateAction, (state, action) => {

        return { 
            ...initialState
        }
    }) 
    //------------------ Send Contact Message----------------------------
    .addCase(sendEmailAction.pending, (state, action) => {
        return { 
            ...state,
            isLoading: true, 
            successMessage: null,
            errorMessage: null,
        }
    })
    .addCase(sendEmailAction.fulfilled, (state, action) => {
        return { 
            ...state,
            isLoading: false, 
            successMessage: "Message sent",
            errorMessage: null,
        }
    })
    .addCase(sendEmailAction.rejected, (state, action) => {
        return { 
            ...state,
            isLoading: false, 
            successMessage: null,
            errorMessage: "Unable to send message",
        }
    }) 

)