import emailjs from '@emailjs/browser';
import { env } from '~/env.mjs';

const publicID = env.NEXT_PUBLIC_EMAILJS_PUBLIC_ID;
const contactTemplateID = env.NEXT_PUBLIC_EMAILJS_CONACT_TEMPLATE_ID;
const contactServiceID = env.NEXT_PUBLIC_EMAILJS_CONACT_SERVICE_ID


/**
 * data object
 * name: 
 * email:
 * phone:
 * subject:
 * message: 
 */
export async function sendContactMail(data: any){
    return await emailjs.send(contactServiceID, contactTemplateID, {...data}, publicID)
}