import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";
import { NavbarState, initialState } from "~/redux/navbar/navbar.state";
import { 
    initNavbarStateAction,
    setNavbarPathAction,
    setNavbarMenuVisibilityAction,
    setNavbarMenuExpandedIndexAction
} from "~/redux/navbar/navbar.actions";

export const navbarReducer = createReducer(
    initialState,
    (builder: ActionReducerMapBuilder<NavbarState>) =>
        builder  
        //------------------ INIT STATE----------------------------
        .addCase(initNavbarStateAction, (state, action) => {
            return { 
                ...initialState
            }
        })              
        //------------------ SET PATH----------------------------
        .addCase(setNavbarPathAction, (state, action) => {
            return { 
                ...state,
                currentPath: action.payload.path
            }
        })              
        //------------------ SET MENU VISIBILITY----------------------------
        .addCase(setNavbarMenuVisibilityAction, (state, action) => {
            return { 
                ...state,
                menuVisibility: action.payload.menuVisibility
            }
        })              
        //------------------ SET Expanded Index----------------------------
        .addCase(setNavbarMenuExpandedIndexAction, (state, action) => {
            return { 
                ...state,
                navbarMenuExapndedIndex: action.payload.index
            }
        })              
)