import { type AppType } from "next/app";
import Head from 'next/head';

//Swiper styles imports
import "swiper/css/bundle";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/grid";

import { trpc } from "~/utils/trpc";

import MyThemeProvider from "~/components/_core/theme/MyThemeProvider";
import { SnackbarProvider } from 'notistack';

//Redux
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import {store} from "~/redux/_core/store";

const persistor = persistStore(store);

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MyThemeProvider>
          <SnackbarProvider preventDuplicate maxSnack={3} SnackbarProps={{style: {fontFamily: "Inter", fontSize: "14px"}}}>
            <Head>
              <title>Vortex Aviation</title>
            </Head>
            <Component {...pageProps}/>  
          </SnackbarProvider>     
        </MyThemeProvider>  
      </PersistGate>  
    </Provider>  
  )
 
};

export default trpc.withTRPC(MyApp);
