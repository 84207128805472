import { createTheme, ThemeOptions } from '@mui/material/styles';
import { merge } from 'lodash';
import { defautlThemeOptions } from './defaultTheme';


const lightThemeOptions: ThemeOptions = {
    palette:{
        mode: "light",
        warning: {
            light: "#ffb4001f",
            main: "#e09e00"
        },
        background: {
            default: "#F5F5F5",
            paper: "#FFFFFF"
        },
        text:{
            primary: "#212121",
            secondary: "#757575"
        }
    }
}


const lightTheme = createTheme(merge(defautlThemeOptions, lightThemeOptions));

export default lightTheme;