import { useCallback } from "react";
import { 
  appThemeNameSelector, 
  isDarkThemeSelector, 
} from '~/redux/global/global.selector';
import {
  setThemeAction, 
} from '~/redux/global/global.actions';
import { useAppSelector, useAppDispatch } from '~/redux/_core/store';

export default function useGlobal(){
    const dispatch = useAppDispatch();
    const appThemeName = useAppSelector(appThemeNameSelector);
    const isDarkTheme = useAppSelector(isDarkThemeSelector);

    const setAppTheme = useCallback(
        (mode: "light" | "dark") => {
          dispatch(
            setThemeAction((mode === "dark")? 'dark' : 'light')
          )
        },
        [dispatch]
      )

    return {
        appThemeName,
        isDarkTheme,
        setAppTheme,
    }
}