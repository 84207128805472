

export interface ContactState {
    isLoading: boolean;
    successMessage: string | null;
    errorMessage: string | null;
}

export const initialState: ContactState = {
    isLoading: false,
    successMessage: null,
    errorMessage: null,    
}