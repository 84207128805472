import { createAction } from "@reduxjs/toolkit";


export const initNavbarStateAction = createAction("INIT_NAVBAR_STATE_ACTION");

export const setNavbarMenuVisibilityAction = createAction(
    "SET_NAVBAR_MENU_VISIBILITY_ACTION",   
    (menuVisibility: boolean) => {
        return {
            payload: {
                menuVisibility
            }
        }
    }    
);

export const setNavbarPathAction = createAction(
    "SET_NAVBAR_PATH_ACTION",   
    (path: string) => {
        return {
            payload: {
                path
            }
        }
    }    
);

export const setNavbarMenuExpandedIndexAction = createAction(
    "SET_NAVBAR_MENU_EXPANDED_INDEX_ACTION",   
    (index: number | null) => {
        return {
            payload: {
                index
            }
        }
    }    
);