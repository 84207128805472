import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {sendContactMail} from '~/utils/email-js'

export const initContactStateAction = createAction("INIT_CONTACT_STATE_ACTION");


export const sendEmailAction = createAsyncThunk<
any,
{data: any},
{rejectValue: any}
>(
  `CONTACT_SEND_EMAIL_ACTION`,
  async (arg: { data?: any }, thunApi) => {

    try {
        const response = await sendContactMail(arg.data);

    } catch (e: any) {

      return thunApi.rejectWithValue({
        error: e,
        message: e.message
      })
    }

  }
)