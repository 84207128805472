import { createTheme, ThemeOptions } from '@mui/material/styles';
import { defautlThemeOptions } from './defaultTheme';
import { merge } from 'lodash';


const darkThemeOptions: ThemeOptions = {
    palette:{
        mode: "dark",
        text:{
            primary: "#FFFFFFCC",
            secondary: "#919EABE1"
        },
        background: {
            default: "#161c24",
            paper: "#161c24",
        },
        grey: {
            "800": "#323232",
            "900": "#222222"
        }
    }
}

const darkTheme = createTheme(merge(defautlThemeOptions, darkThemeOptions));

export default darkTheme;