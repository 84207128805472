import useGlobal from '~/hooks/redux/global/useGlobal';

//MUI
import { ThemeProvider } from '@mui/material/styles';
import  CssBaseline  from '@mui/material/CssBaseline';
import lightTheme from "~/components/_core/theme/lightTheme";
import darkTheme from "~/components/_core/theme/darkTheme";



export default function MyThemeProvider({children} : {children: React.ReactNode}){
    
    const {isDarkTheme} = useGlobal();

    return (
        <ThemeProvider theme={isDarkTheme? darkTheme : lightTheme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}