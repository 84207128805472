import {
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { RootState, persistedReducer } from "~/redux/_core/rootReducers";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import thunk from "redux-thunk";

const middlewares = [...getDefaultMiddleware<RootState>(), thunk]

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    thunk: true
  })
})
  
export type AddDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;