import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "~/redux/_core/rootReducers";
import {GlobalState} from "~/redux/global/global.state";

const featureStateSelector = (state: RootState) => state.global;

export const appThemeNameSelector = createSelector(
    featureStateSelector,
    (state: GlobalState) => state.themeName
 )

export const isDarkThemeSelector = createSelector(
    featureStateSelector,
    (state: GlobalState) => state.isDarkTheme
)