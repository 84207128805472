export interface NavbarState {
    menuVisibility: boolean;
    navbarMenuExapndedIndex: number | null;
    currentPath: string;
}

export const initialState: NavbarState = {
    menuVisibility: false,
    navbarMenuExapndedIndex: null,
    currentPath: "/",
}