import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Reducers
import { globalReducer } from "~/redux/global/global.reducer";
import { navbarReducer } from "~/redux/navbar/navbar.reducer";
import { contactReducer } from "~/redux/contact/contact.reducer";
/**
 * Combine reducers
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript
 */
export const rootReducers = combineReducers({
  global: globalReducer,
  navbar: navbarReducer,
  contact: contactReducer
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['global']
};

export const persistedReducer = persistReducer(persistConfig, rootReducers);

  
export type RootState = ReturnType<typeof rootReducers>