import { ActionReducerMapBuilder, createReducer } from "@reduxjs/toolkit";
import { GlobalState, initialState } from "~/redux/global/global.state";
import { 
    setThemeAction,
} from "~/redux/global/global.actions";

export const globalReducer = createReducer(
    initialState,
    (builder: ActionReducerMapBuilder<GlobalState>) =>
        builder  
        //------------------ Set Theme----------------------------
        .addCase(setThemeAction, (state, action) => {

            const themeName = action.payload;
            const isDark = (themeName === "dark")? true : false;

            return { 
                ...state,
                themeName: themeName,
                isDarkTheme: isDark   
            }
        })       
)